<template>
  <div class="hub-calendar-detail">
    <CalendarDetail :content="content.detail" />

    <div class="hub-calendar-detail__documents" v-if="content.documents.length">
      <BaseTitle :tag="'h3'">Related document</BaseTitle>
      <div class="hub-calendar-detail__documents__wrapper">
        <CardDocument v-for="(item, index) in content.documents" display="large" :content="item" :key="`documents-${index}`" />
      </div>

    </div>

    <div class="hub-calendar-detail__guests" v-for="(supItem, index) in content.guests" :key="`guests-${index}`">
      <BaseTitle :tag="'h3'">{{supItem.title}}</BaseTitle>
      <ElementTable>
        <template slot="header">
          <UserTableHeader :has-checkbox="true" />
        </template>
        <template slot="body">
          <UserTableBody
            :content="subItem"
            :key="`user-${index}`"
            :has-checkbox="true"
            v-for="(subItem, index) in supItem.items" />
        </template>
      </ElementTable>
    </div>

  </div>
</template>

<script>
import CardDocument from '@cards/document/CardDocument'
import ElementTable from '@elements/ElementTable'
import UserTableHeader from '@blocks/user/UserTableHeader'
import UserTableBody from '@blocks/user/UserTableBody'
import CalendarDetail from '@blocks/calendar/CalendarDetail'
import BaseTitle from '@base/BaseTitle'

export default {
  name: 'HubCalendarDetail',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    ElementTable,
    UserTableHeader,
    UserTableBody,
    CardDocument,
    CalendarDetail,
    BaseTitle
  }
}
</script>

<style lang="scss">
  .hub-calendar-detail {
   &__documents , &__guests {
     > .title {
      margin-top: layout(spacing);
      margin-bottom: layout(spacing);
      padding-bottom: layout(spacing) / 2;
      border-bottom: 1px solid colors(border);
     }

    }

    &__documents {
      &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 3rem;
        .card-document {
          flex: 0 1 auto;
          margin-bottom: layout(spacing);

          @include media('xlarge') {
            width: calc((100%  - #{layout("spacing")}) / 2 );

            &:nth-of-type(even) {
              margin-left: layout(spacing);
            }
          }
          @include media('large-only') {
            width: 100%;
          }
          @include media('small-medium') {
            width: 100%;
          }

        }
      }

    }

    &__guests {
      .table {
        margin-bottom: 4rem;
      }
    }

  }
</style>
