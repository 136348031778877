<template>
  <div class="calendar-detail">
    <!-- Event detail -->
    <ContainerBlue :baseLink="true" :guid="content.guid">
      <template slot="top__left">

          <div class="schedule">
            <div class="schedule__date">
              <ElementIconText :icon="item.icon" v-for="(item, i) in eventDate" :text="item.content" :key="`eventDate-${i}`" />
            </div>
            <div class="schedule__time">
              <ElementIconText :icon="item.icon" v-for="(item, i) in eventTime" :text="item.content" :key="`eventTime-${i}`" />
            </div>
          </div>

          <ElementIconText :icon="item.icon" v-for="(item, i) in eventDetail" v-show="item.content" :text="item.content" :key="`eventDetail-${i}`" />

          <ElementIconText icon="pin" v-if="content.location || content.map">
            <div>
              <div v-html="content.location" v-if="content.location"></div>
              <BaseButton
                clear target="_blank"
                :url="content.map"
                v-if="content.map">
                {{$t('message.common.buttons.openMap')}}
              </BaseButton>
            </div>
          </ElementIconText>

          <div v-if="content.diner">
            <p>&nbsp;</p>

            <ElementIconText :icon="item.icon" v-for="(item, i) in dinerDetail" :text="item.content" :key="`dinerDetail-${i}`" />
            <div class="schedule">
              <div class="schedule__date">
                <ElementIconText :icon="item.icon" v-for="(item, i) in dinerDate" :text="item.content" :key="`dinerDate-${i}`" />
              </div>
              <div class="schedule__time">
                <ElementIconText :icon="item.icon" v-for="(item, i) in dinerTime" :text="item.content" :key="`dinerTime-${i}`" />
              </div>
            </div>

            <!-- diner place -->
            <ElementIconText icon="pin" v-if="content.diner.schedule.end.time">
              <div>
                <div> {{ `Venue: ${content.diner.address.location}` }} </div>
                <div> {{ `${content.diner.address.street}, ${content.diner.address.number}` }} </div>
                <div> {{ `${content.diner.address.zipcode}, ${content.diner.address.city}` }} </div>
                <div> {{ `${content.diner.address.country}` }} </div>
                <BaseButton
                  clear
                  target="_blank"
                  :url="getGoogleLink(content.diner.address)">
                  {{$t('message.common.buttons.openMap')}}
                </BaseButton>
              </div>
            </ElementIconText>
          </div>
      </template>

      <template slot="top__right" v-if="content.disponibility">
        <CalendarDetailDropdownCustomText
          :text="$t('message.common.buttons.attendevent')"
          v-if="!content.accessByPhone"
        >
          <BaseButton
            :active="content.status === 'attend'"
            @click.native="attendingEvent('attend')">
              {{$t('message.common.buttons.attendInPerson')}}
          </BaseButton>
          <BaseButton
            :active="content.status === 'online'"
            @click.native="attendingEvent('online')">
              {{$t('message.common.buttons.attendOnline')}}
          </BaseButton>
        </CalendarDetailDropdownCustomText>

        <!-- <BaseButton
          :active="content.status === 'attend'"
          v-if="!content.accessByPhone"
          icon="chevron-right"
          icon-position="right"
          @click.native="attendingEvent('attend')">
          {{$t('message.common.buttons.attendevent')}}
        </BaseButton> -->

        <BaseButton
          v-if="content.accessByPhone"
          :active="content.status === 'phone'"
          icon="chevron-right"
          icon-position="right"
          @click.native="attendingEvent('phone')">
          {{$t('message.common.buttons.attendViaPhone')}}
        </BaseButton>

        <BaseButton
          :active="content.status === 'apologies'"
          icon="chevron-right"
          icon-position="right"
          @click.native="attendingEvent('apologies')">
          {{$t('message.common.buttons.apologies')}}
        </BaseButton>

        <CalendarDetailDropdown
          v-if="content.diner && content.status != 'online'"
        >
          <BaseButton
            :active="content.diner.status"
            @click.native="attendingDiner(true)">
              {{$t('message.common.buttons.yes')}}
          </BaseButton>
          <BaseButton
            :active="!content.diner.status"
            @click.native="attendingDiner(false)">
              {{$t('message.common.buttons.no')}}
          </BaseButton>
        </CalendarDetailDropdown>

      </template>

      <template slot="bottom">
        <BaseButton
          :icon="'more'"
          icon-position="left"
          :url="content.ical"
          v-if="content.disponibility"
          clear>
          Add this event in your calendar
        </BaseButton>
      </template>

    </ContainerBlue>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import { fetchPostService } from '@modules/api'

import ContainerBlue from '@containers/ContainerBlue'
import ElementIconText from '@elements/ElementIconText'
import BaseButton from '@base/BaseButton'
import CalendarDetailDropdown from './CalendarDetailDropdown'
import CalendarDetailDropdownCustomText from './CalendarDetailDropdownCustomText'

export default {
  name: 'CalendarDetail',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    ContainerBlue,
    ElementIconText,
    BaseButton,
    CalendarDetailDropdown,
    CalendarDetailDropdownCustomText
  },
  data () {
    return {
      program: {
        event: false,
        diner: false
      }
    }
  },
  computed: {
    eventDetail () {
      return this.getEventDetail()
    },
    eventDate () {
      return this.getEventDate()
    },
    eventTime () {
      return this.getEventTime()
    },
    dinerDetail () {
      return this.getDinerDetail()
    },
    dinerDate () {
      return this.getDinerDate()
    },
    dinerTime () {
      return this.getDinerTime()
    }
  },
  methods: {
    ...mapActions({
      updateDetailItems: 'events/updateDetailItems',
      fetchGetPage: 'page/fetchGetPage',
      fetchData: 'filters/fetchData'
    }),
    // handleIcsExport () {
    //   fetchGetService('export-ics', { guid: this.content.guid })
    // },
    getGoogleLink (address) {
      const street = address.street ? address.street.trim().split(' ').join('+') : ''
      const number = address.number ? address.number.trim().split(' ').join('+') : ''
      const zipcode = address.zipcode ? address.zipcode.trim().split(' ').join('+') : ''
      const city = address.city ? address.city.trim().split(' ').join('+') : ''
      const country = address.country ? address.country.trim().split(' ').join('+') : ''
      return `https://www.google.com/maps/place/${street}+${number}+${zipcode}+${city}+${country}`
    },
    getEventDetail () {
      const responsible = this.content.responsible && this.content.responsible ? this.content.responsible : null
      const lunch = this.content.lunch ? 'Lunch Provided' : null
      return [
        {
          icon: 'user',
          content: responsible
        },
        {
          icon: 'flatware',
          content: lunch
        }
      ]
    },
    getEventDate () {
      const startDate = this.content.schedule && this.content.schedule.start.date ? `Start: ${this.content.schedule.start.date}` : null
      return [
        {
          icon: 'events',
          content: startDate
        }
      ]
    },
    getEventTime () {
      const startTime = this.content.schedule && this.content.schedule.start.time ? this.content.schedule.start.time : null
      const endTime = this.content.schedule && this.content.schedule.end.time ? this.content.schedule.end.time : null
      return [
        {
          icon: 'clock',
          content: startTime
        },
        {
          icon: 'clock',
          content: endTime
        }
      ]
    },
    getDinerDetail () {
      const location = this.content.diner && this.content.diner.address.location ? `Dinner provided : ${this.content.diner.address.location}` : null
      const description = this.content.diner && this.content.diner.description ? this.content.diner.description : null
      return [
        {
          icon: 'flatware',
          content: location
        },
        {
          icon: 'pencil',
          content: description
        }
      ]
    },
    getDinerDate () {
      const startDate = this.content.diner && this.content.diner.schedule.start.date ? this.content.diner.schedule.start.date : null
      return [
        {
          icon: 'events',
          content: startDate
        }
      ]
    },
    getDinerTime () {
      const startTime = this.content.diner && this.content.diner.schedule.start.time ? this.content.diner.schedule.start.time : null
      const endTime = this.content.diner && this.content.diner.schedule.end.time ? this.content.diner.schedule.end.time : null
      return [
        {
          icon: 'clock',
          content: startTime
        },
        {
          icon: 'clock',
          content: endTime
        }
      ]
    },

    attendingEvent (status) {
      const detail = []
      const content = cloneDeep(this.content)
      content.status = status
      detail.push(content)
      this.updateDetailItems(detail)
      this.$nextTick(() => {
        this.fetchPostStatus()
      })
    },
    attendingDiner (status) {
      const detail = []
      const content = cloneDeep(this.content)
      content.diner.status = status
      detail.push(content)
      this.updateDetailItems(detail)
      this.$nextTick(() => {
        this.fetchPostStatus()
      })
    },
    fetchPostStatus () {
      fetchPostService('calendar-detail-status', {
        guid: this.content.guid,
        event: this.content.status,
        diner: this.content.diner ? this.content.diner.status : null
      })
        .then(res => {
          this.updateEventDetails()
        })
    },
    updateEventDetails () {
      const service = 'calendar-detail'
      const params = { eventId: this.content.guid }

      this.fetchGetPage({ service, params }).then(() => {
        this.fetchData({ route: this.$route.name, context: 'view' })
      })
    }
  }
}
</script>

<style lang="scss">
  .calendar-detail {
    .icon-text {
      color: white;

      .icon {
        &::before {
        color: white
        }
      }
    }

    .button.is-active{
      background-color: lighten(colors("holidays"), 5%);
    }
  }
  .schedule{
    @include media('large') {
      margin-bottom: 1rem;
      display: flex;
      &__date{
        padding-right: 4rem;
      }
    }
  }
</style>
