<template>
  <div class="calendar-events-details-view">
    <HubCalendarDetail :content="content" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import HubCalendarDetail from '@blocks/hubs/HubCalendarDetail'

export default {
  name: 'CalendarEventDetailsView',
  mixins: [ updateBreadcrumb ],
  props: {
    eventId: {
      type: String,
      required: false
    }
  },
  components: {
    HubCalendarDetail
  },
  computed: {
    ...mapGetters({
      detail: 'events/getDetail',
      documents: 'documents/getItems',
      guests: 'guests/getItems'
    }),
    content () {
      return {
        detail: this.detail,
        documents: this.documents,
        guests: this.guests
      }
    }
  }
}
</script>
