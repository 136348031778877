<template>
<div class="calendar-dropdown">
    <BaseButton
      :icon="active ? 'chevron-up' : 'chevron-down'"
      icon-position="right"
      @click.native="handleClick">
      <ElementIconText icon="flatware" :text="$t('message.common.buttons.attendingDiner')" />
    </BaseButton>

    <div class="calendar-dropdown__content" v-show="active">
      <slot />
    </div>
  </div>
</template>

<script>
import ElementIconText from '@elements/ElementIconText'
import BaseButton from '@base/BaseButton'

export default {
  name: 'CalendarDetailDropdown',
  props: {
  },
  components: {
    ElementIconText,
    BaseButton
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
  },
  watch: {
    forceActive () {
      this.active = this.forceActive
    }
  },
  methods: {
    handleClick () {
      this.active = !this.active
    }
  }
}
</script>

<style lang="scss">
.calendar-dropdown{
  position: relative;

  @include media('small-medium'){
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  @include media('large'){
    display: block;
  }

  & > .button{
    width: 100%;
  }

  &__content{
    padding: 1rem;
    background: rgba(0,0,0,.5);
    border-radius: 0.4rem;

    @include media('small-medium'){
      flex: 0 1 auto;
      position: relative;
      display: inline-block;
      margin: 0 0 0 auto;
    }
    @include media('large'){
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 99;
    }

    .button{
      margin: 0 0 0 1rem;

      &:first-child{
        margin-left: 0;
      }
    }

    &::before{
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 7px 0 7px;
      border-color: rgba(0,0,0,.5) transparent transparent transparent;
      display: block;
      content: "";
      right: 1rem;
      top: 0;
      transform: translate(-50%, -100%) rotate(180deg);
    }
  }
}
</style>
