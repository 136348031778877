<template>
  <div class="container-blue">

    <slot name="custom"></slot>

    <div class="container-blue__top">
      <div class="container-blue__top__left">
          <slot name="top__left"></slot>
      </div>

      <div class="container-blue__top__right">
          <slot class="" name="top__right"></slot>
      </div>
    </div>

    <div class="container-blue__bottom" >
          <slot class="" name="bottom"></slot>

      <!-- <BaseButton
        :icon="'more'"
        icon-position="left"
        :route="route"
        :target="'_blank'"
        clear>
        Add this event in your calendar
      </BaseButton> -->
    </div>

  </div>
</template>

<script>
// import BaseButton from '@base/BaseButton'

export default {
  name: 'ContainerBlue',
  props: {
    baseLink: {
      type: Boolean,
      required: false
    },
    guid: {
      type: String,
      required: false
    }
  },
  components: {
    // BaseButton
  }

}
</script>

<style lang="scss">
.container-blue {
  @media print {
    margin-bottom: layout("spacing")*1.5;
    padding: layout("spacing");
    background-color: transparent;
    border-left: 6px solid shade(colors("primary"), 15%);

    .icon-text{
      color: black !important;
      a{
        display: none !important;
      }
      .icon{
        &:before{
          color: black !important;
        }
      }
    }

  }

  @include media('small-medium') {
    border-top: 6px solid shade(colors(primary), 20%);
    color: white;
  }

  @include media('large') {
    border-left: 6px solid shade(colors(primary), 20%);
    color: white;
  }
  margin-bottom: 2rem;border-radius:.4rem;
  padding: 2rem;
  background-color: colors(primary);
  overflow: hidden;

  &__bottom {
    @media print {
      display: none !important;
    }
    // margin-bottom: -2rem;
    margin-left: -2rem;
    margin-right: -2rem;

    .button {
      @include media('large') {
        &:hover {
          color: white;
          background-color: shade(colors(primary), 30%);
        }
      }
      display: block;
      border-radius: 0;
      margin-top: 1rem;
      padding: 1rem;
      text-align: center;
      color: white;
      background-color: shade(colors(primary), 10%);
    }
  }

  &__top {
    @include media('small-medium') {
      flex-direction: column;
    }
    display: flex;
    justify-content: space-between;

    &__left {
      @include media('small-medium') {
      width: 100%;
      }
      flex: 0 1 auto;
      width: 65%;
    }

    &__right {
      @media print {
        display: none !important;
      }
      @include media('small-medium') {
        width: 100%;
        margin-top: layout(spacing);
      }
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      width: 38%;

      .button {
        background-color: shade(colors(primary), 10%);
        margin-bottom: 1rem;
        padding: 1.5rem;
        font-size: 1.5rem;
        justify-content:space-between;

        &:hover, &.is-active {
          background-color: shade(colors(primary), 20%);
          color: white;
        }
      }
    }

  }
}

</style>
